import {createRouter, createWebHistory} from 'vue-router'
import DefaultLayout from "../layouts/DefaultLayout.vue";
import {SERVICE_URL} from "@/serviceResolver";

function isUserAuthenticated() {
    return localStorage.getItem('authToken') !== null;
}

const routes = [
    {
        path: '/r',
        redirect: (to) => {
            window.location.replace(`${SERVICE_URL}/redirect/${to.query.token}`)
        }
    },
    {
        path: '/r/:token',
        redirect: (to) => {
            window.location.replace(`${SERVICE_URL}/redirect/${to.params.token}`)
        }
    },
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard',
        meta: {
            public: false,
            title: 'Coherent Healthcare'
        },
        children: [
            {
                path: '/dashboard',
                name: 'Summary',
                component: () =>
                    import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
            },
            {
                path: '/dashboard/till',
                name: 'Till',
                component: () => import('../views/Till.vue'),
            },
            {
                path: '/dashboard/transactions',
                name: 'Transactions',
                component: () => import('../views/Transactions.vue'),
            },
            {
                path: '/dashboard/settings',
                name: 'Settings',
                component: () => import('../views/Settings.vue'),
            },
            {
                path: '/dashboard/mailto/:email',
                name: 'EmailLink',
                // redirect: to => {
                //     // const email = to.params.email;
                //     window.location.href = `#`;
                // },
                beforeEnter: (to, from, next) => {
                    const email = to.params.email;
                    window.location.href = `mailto:${email}`;
                    next(false);
                }
            }
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: {
            public: true,
            title: 'Coherent Healthcare - Login'
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

router.beforeEach((to, from, next) => {
    const requiresAuth = !to.matched.some(record => record.meta.public);
    if (requiresAuth && !isUserAuthenticated()) {
        next({name: 'Login'});
    } else if (to.name === 'Login' && isUserAuthenticated()) {
        next({name: 'Home'});
    } else {
        next();
    }
});

export default router
