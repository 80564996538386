import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import axios from "axios";
import Loader from "@/components/Loader.vue";
import {SERVICE_URL} from "@/serviceResolver";

const app = createApp(App)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Loader', Loader);

app.mount('#app');

async function initializeApp() {
    try {
        const token = localStorage.getItem('authToken');
        if (token) {
            axios.defaults.headers.common['access-token'] = token;
            await axios.get(`${SERVICE_URL}/init`);

        }
    } catch (error) {
        axios.defaults.headers.common['access-token'] = null;
        localStorage.removeItem('authToken');
        router.push({ name: 'Login' });
    }
}

initializeApp();
