export default [
    {
        component: 'CNavItem',
        name: 'Summary',
        to: '/dashboard',
        icon: 'dashboard',
        // badge: {
        //   color: 'primary',
        //   text: 'NEW',
        // },
    },
    {
        component: 'CNavItem',
        name: 'Till',
        to: '/dashboard/till',
        icon: 'till',
    },
    {
        component: 'CNavItem',
        name: 'Transactions',
        to: '/dashboard/transactions',
        icon: 'transactions',
    },
    {
        component: 'CNavItem',
        name: 'Support',
        to: '/dashboard/mailto/hello@coherenthealthcare.com',
        icon: 'support',
    },
    {
        component: 'CNavItem',
        name: 'Settings',
        to: '/dashboard/settings',
        icon: 'settings',
    }


    // {
    //   component: 'CNavItem',
    //   name: 'Download CoreUI',
    //   href: 'http://coreui.io/vue/',
    //   icon: { name: 'cil-cloud-download', class: 'text-white' },
    //   _class: 'bg-success text-white',
    //   target: '_blank'
    // },
    // {
    //   component: 'CNavItem',
    //   name: 'Try CoreUI PRO',
    //   href: 'http://coreui.io/pro/vue/',
    //   icon: { name: 'cil-layers', class: 'text-white' },
    //   _class: 'bg-danger text-white',
    //   target: '_blank'
    // }
]
