<template>
  <CSidebar
    position="fixed"
    :unfoldable=false
    :visible=true
  >
    <CSidebarBrand @click="this.$router.push('/dashboard')">
      <img alt="Coherent logo" class="sidebar-brand-full clickable" src="/images/coherent.png"/>
      <img alt="Small coherent logo" class="sidebar-brand-narrow clickable" src="/images/coherent-sm.png"/>
    </CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { AppSidebarNav } from './AppSidebarNav'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  }
}
</script>
