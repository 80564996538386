<template>
  <Loader v-if="loader"/>
  <CHeader position="sticky">
    <CContainer lg>
      <CContainer lg>
        <CRow>
          <CCol :md="6" class="header-title">
            <div class="d-inline-flex align-items-center transactions-header"
                 :class="(($route.name == 'Transactions')?' ps-0':'')">
              <!-- <CHeaderToggler class="ps-1 mx-auto d-inline" @click="$store.commit('toggleSidebar')">
                <CIcon icon="cil-menu" size="lg" />
              </CHeaderToggler> -->
              <AppBreadcrumb/>
            </div>
          </CCol>
          <CCol :md="6" class="d-flex justify-content-end align-items-center">
            <CNavLink @click="logout()" class="logout clickable">
              <svg width="16px" height="12px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5H1.5L1.5 1.5L8 1.5"
                      stroke="#000000"/>
              </svg>
            </CNavLink>
          </CCol>
        </CRow>
      </CContainer>
      <!-- <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand> -->
      <!-- <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Settings</CNavLink>
        </CNavItem>
      </CHeaderNav> -->
      <CHeaderNav>
        <!-- <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>  -->
        <!-- <AppHeaderDropdownAccnt />  -->
      </CHeaderNav>
    </CContainer>
    <!-- <CHeaderDivider /> -->
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import {logo} from '../assets/brand/logo'
import axios from "axios";
import {useRouter} from "vue-router";
import {SERVICE_URL} from "@/serviceResolver";

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      loader: false
    }
  },
  setup() {
    const router = useRouter();
    const logout = async () => {
      try {
        await axios.post(`${SERVICE_URL}/user/logout`);
        localStorage.removeItem('authToken');
        router.push({name: 'Login'});
      } catch (error) {
        console.error("Error:", error);
      }
    }

    return {
      logout,
      logo
    };
  },
  methods: {
    async handleLogin() {
      this.loader = true
      await this.logout();
      this.loader = false
    }
  }
}
</script>
